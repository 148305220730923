* {
    box-sizing: border-box;
    margin: 0;
}

html {
    font-size: 14px;
}

body {
    margin: 0;
    font-family: Inter;
    padding: 0 12rem;
}

.app {
    padding: 0 10rem;
}

.main {
    padding: 0 2rem;
    display: flex;
    flex-direction: column;
    gap: 35px;
}

.section-header {
    display: flex;
    margin: 0;
    /* padding: 25px 0px; */
}

.comma-space {
    padding-right: 0.7rem;
}

.work-experience, .education, .skills, .certifications {
    display: flex;
    flex-direction: column;
    gap: 12px;
}


/* Header */
.header {
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.header > * {
    flex-basis: 100%;
}

.header--name {
    align-self: center;
    margin: 2.5rem 0 0 0;
    font-size: 2.5rem;
}

.header--contact-info {
    display: flex;
    justify-content: space-evenly;
}

.header--contact-info--item {
    display: flex;
    align-items: center;
    /* font-size: 0.95rem; */
}

.header--contact-info--item > a, .header--contact-info--item > p {
    padding-left: 4px;
    margin: 0;
}

.header--end {
    border: 1px solid darkgrey;
    width: 100%;
    margin: 1rem 0 2rem 0;
}


/* WorkExperience */
.work-experience--jobs {
    display: flex;
    flex-direction: column;
    gap: 1.5rem;
    padding: 0px 2rem;
}

.work-experience--jobs--job {
    display: flex;
    flex-direction: column;
    gap: 0.7rem;
}

.work-experience--jobs--job--info {
    display: flex;
    justify-content: space-between;
}

.work-experience--jobs--job--info--headline {
    display: flex;
    font-size: 1.1rem;
}

.work-experience--jobs--job--info--headline > p {
    margin: 0px;
}

.work-experience--jobs--job--info--headline--title {
    font-weight: bold;
}

.work-experience--jobs--job--info--headline--company {
    font-style: italic;
}

.work-experience--jobs--job--info--date {
    margin: 0px;
}

.work-experience--jobs--job--bullets {
    display: flex;
    flex-direction: column;
    gap: 1rem;
    font-size: 0.95rem;
    padding-left: 2.5rem;
}


/* Certifications */
.certifications--certifications > ul {
    display: flex;
    gap: 10px;
    padding: 0 2.5rem;
    list-style: none;
    justify-content: space-between;
}


/* Skills */
.skills--skills > ul {
    display: flex;
    gap: 10px;
    padding: 0 2.5rem;
    list-style: none;
    justify-content: space-between;
}


/* Education */
.education--schools {
    padding: 0px 30px;
    /* font-size: 0.95rem; */
}

.education--schools--school--info {
    display: flex;
}

.education--schools--school--degree {
    font-weight: bold;
}



@media print {
    html {
        font-size: 11px;
    }

    body {
        font-family: Inter;
        margin: 0!important;
        padding: 0!important;
    }

    .app {
        padding: 0;
    }

    .main {
        /* padding: 0 25px; */
        padding: 0;
        display: flex;
        flex-direction: column;
        gap: 35px;
    }

    .section-header {
        display: flex;
        margin: 0;
        /* padding: 25px 0px; */
    }

    .comma-space {
        padding-right: 0.4rem;
    }

    .work-experience, .education, .skills, .certifications {
        display: flex;
        flex-direction: column;
        gap: 12px;
    }


    /* Header */
    .header {
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

    .header > * {
        flex-basis: 100%;
    }

    .header--name {
        align-self: center;
        margin: 0.3rem 0 0 0;
        font-size: 2.5rem;
    }

    .header--contact-info {
        display: flex;
        justify-content: space-evenly;
    }

    .header--contact-info--item {
        display: flex;
        align-items: center;
        /* font-size: 0.95rem; */
    }

    .header--contact-info--item > a, .header--contact-info--item > p {
        padding-left: 4px;
        margin: 0;
    }

    .header--end {
        border: 1px solid darkgrey;
        width: 100%;
        margin: 1rem 0 2rem 0;
    }


    /* WorkExperience */
    .work-experience--jobs {
        display: flex;
        flex-direction: column;
        /* font-size: 0.95rem; */
        gap: 1.5rem;
        padding: 0px 2rem;
    }

    .work-experience--jobs--job {
        display: flex;
        flex-direction: column;
        gap: 0.7rem;
    }

    .work-experience--jobs--job--info {
        display: flex;
        justify-content: space-between;
    }

    .work-experience--jobs--job--info--headline {
        display: flex;
        font-size: 1.05rem;
    }

    .work-experience--jobs--job--info--headline > p {
        margin: 0px;
    }

    .work-experience--jobs--job--info--headline--title {
        font-weight: bold;
    }

    .work-experience--jobs--job--info--headline--company {
        font-style: italic;
    }

    .work-experience--jobs--job--info--date {
        margin: 0px;
    }

    .work-experience--jobs--job--bullets {
        display: flex;
        flex-direction: column;
        gap: 1rem;
        font-size: 0.95rem;
        padding-left: 2.5rem;
    }


    /* Certifications */
    .certifications--certifications > ul {
        display: flex;
        gap: 10px;
        padding: 0 2.5rem;
        list-style: none;
        justify-content: space-between;
    }


    /* Skills */
    .skills--skills > ul {
        display: flex;
        gap: 10px;
        padding: 0 2.5rem;
        list-style: none;
        justify-content: space-between;
    }


    /* Education */
    .education--schools {
        padding: 0px 30px;
        /* font-size: 0.95rem; */
    }

    .education--schools--school--info {
        display: flex;
    }

    .education--schools--school--degree {
        font-weight: bold;
    }
}

@page {
    margin: 1.25cm 2cm 1.5cm;
}
@page:first {
    margin: 0.25cm 2cm 1.5cm;
}
/* @page:left {
    margin: 2cm 1.5cm 2cm 2cm;
}
@page:right {
    margin: 2cm 2cm 2cm 1.5cm;
} */
